a {
  text-decoration: none;
}

.disabled {
  opacity: 0.6;
  pointer-events: none !important;
}

button:focus {
  outline: none !important;
}

/* remove number symbol from input */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for firefox */
input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

/* INPUT TYPE DATE ICON CSS START */
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover,
input[type="time"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}

/* INPUT TYPE DATE ICON CSS ENDS */

.bg-red{
  background-color: red!important;
  color: #fff!important;
}

.bg-green{
  background-color: green!important;
  color: #fff!important;
}

/* CONTAINER LAYOUT STYLE */
@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 576px) {
  .container {
    max-width: 100%;
    margin: 0 15px;
  }
}

/* SCROLLBAR CSS START */
/* width */
.customScroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #f2f2f2;
  border-radius: 10px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #c9c9c9;
}
/* SCROLLBAR CSS ENDS */

/* KEYFRAMES START */

@keyframes jumpFour {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }
  50% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translateZ(0);
  }
}
/* KEYFRAMES ENDS */

:root {
  --custom-color-table: #42275a !important;
}
