@import "./common.scss";

.footer_main {
  padding: 40px 0;
  margin-top: 100px;
  background-color: #0000000e;
  @media screen and (max-width: 991px) {
    margin-top: 70px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }

  .footer_content {
    .quickLinks {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      font-size: 18px;
      margin-bottom: 30px;
      flex-wrap: wrap;
      row-gap: 20px;
      > a {
        color: #000;
        transition: 0.4s;
        &:hover {
          color: $textColor;
          text-decoration: underline;
        }
      }
    }

    .socialLinks {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
      margin-bottom: 30px;
      > a {
        color: #000;
        transition: 0.4s;
        font-size: 30px;
        display: flex;
        &:hover {
          color: $textColor;
          text-decoration: underline;
        }
      }
    }

    .copyrightText {
      text-align: center;
      color: $textColor;
    }
  }
}
