@import "./common.scss";

.teamTree_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  background-color: #000;
  border-radius: 7px;
  padding: 10px;
  //   box-shadow: 3px 2px 4px 1px lightgrey;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background-color: #f2f2f2;
    box-shadow: 3px 2px 4px 1px lightgrey;
    color: #000;
  }

  .name_size {
    display: flex;
    align-items: center;
    column-gap: 10px;
    .team_count {
      background-color: #f2f2f2;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      font-size: 14px;
    }
    .associate_name {
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
      column-gap: 4px;
      > span {
        font-size: 14px;
      }
    }
  }
  .arrowHandle {
    display: flex;
    justify-self: flex-end;
  }
}

// LOADER STYLE START
.loader_div_main {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border-top: 2px solid #f2f2f2;
    border-right: 2px solid #f2f2f2;
    animation: rotate 0.4s linear infinite backwards;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// LOADER STYLE ENDS
