@import "./common.scss";

.login_main {
  background-color: #80808045;
  position: relative;
  .loginContent {
    height: 100vh;
    width: 100%;

    .back {
      padding: 25px 0;
      > a {
        color: $mainColor;
        transition: 0.3s;
        font-weight: 500;
        font-size: 18px;
        &:hover {
          color: $textColor;
        }
      }
    }
    form.login_form {
      width: 600px;
      display: block;
      margin: 0 auto;
      background-color: #fff;
      padding: 20px;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 20px;
      box-shadow: 0px 0px 8px 0px #00000024;

      @media screen and (max-width: 630px) {
        // width: calc(100% - 30px);
        width: 100%;
        transform: unset;
        top: unset;
        left: 0;
        bottom: 0;
        border-radius: 20px 20px 0 0;
        height: calc(100vh - 79px);
        background: rgba(255, 255, 255, 0.9);
      }

      > h1 {
        text-align: center;
        font-weight: 500;
        font-size: 40px;
        color: $mainColor;
        margin-bottom: 10px;
      }
      > p {
        color: $textColor;
        font-size: 18px;
        margin-bottom: 20px;
      }

      .myInput {
        label {
          color: $mainColor;
        }
        input {
          color: $textColor;
          border: 1px solid $textColor;
        }
      }

      .forgot_pass_btn {
        color: $textColor;
        margin-bottom: 15px;
        display: block;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }

      button {
        color: #fff;
        background: $mainColor;
        border: none;
        outline: none;
        padding: 7px 10px;
        display: block;
        margin: 0 auto;
        font-size: 18px;
        cursor: pointer;
        border-radius: 7px;
        transition: 0.4s;

        &:hover {
          background: $textColor;
        }
      }
    }
  }
}
