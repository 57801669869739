@import "./common.scss";

.contactUs_main {
  margin-top: 100px;
  @media screen and (max-width: 991px) {
    margin-top: 70px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 50px;
  }
  .secTitle {
    margin-bottom: 50px;

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  .top_section {
    display: flex;
    // justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      row-gap: 20px;
    }
    > div {
      text-align: center;
      flex: 1;
      padding: 15px 10px;
      box-sizing: border-box;
      transition: 0.4s;
      cursor: pointer;
      border: 1px solid transparent;

      &:first-child {
        background-color: rgb(255, 254, 231);
      }
      &:nth-child(2) {
        background-color: rgb(251, 241, 255);
      }
      &:last-child {
        background-color: rgb(238, 251, 250);
      }

      &:hover {
        border: 1px solid $mainColor;
        background-color: unset;
      }

      a {
        color: $mainColor;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }

      @media screen and (max-width: 767px) {
        border-radius: 20px;
        overflow: hidden;
      }

      > .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 10px;
        border: 2px solid $mainColor;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          margin-bottom: 8px;
        }
        svg {
          font-size: 25px;
          color: black;
        }
      }

      > h3 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          margin-bottom: 8px;
        }
      }
      > p {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .message_section {
    margin-top: 100px;
    @media screen and (max-width: 991px) {
      margin-top: 70px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 50px;
    }
    > h3 {
      text-align: center;
      font-size: 28px;
      margin-bottom: 50px;
      font-weight: 500;
      @media screen and (max-width: 767px) {
        margin-bottom: 25px;
        font-size: 22px;
      }

      &:after {
        content: "";
        border-bottom: 2px solid $mainColor;
        width: 60%;
        display: block;
        margin: 5px auto;
      }

      > span {
        color: $mainColor;
      }
    }

    > .form {
      > input,
      > textarea {
        width: -webkit-fill-available;
        padding: 10px;
        font-size: 18px;
        border: none;
        border-bottom: 1px solid $mainColor;
        color: $textColor;
        outline: none;
        margin-bottom: 25px;
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        display: block;
        margin: 0 auto;
        padding: 10px 30px;
        border-radius: 50px;
        border: 1px solid $mainColor;
        color: #f2f2f2;
        background-color: $mainColor;
        font-size: 18px;
        cursor: pointer;
        transition: .4s;

        &:hover{
            color: $mainColor;
            background-color: #f2f2f2;
        }
      }
    }
  }
}
