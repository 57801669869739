@import "./common.scss";

.homePage__main {
  .home_content {
    .hero_sec {
      padding: 100px 0;
      position: relative;
      overflow: hidden;
      @media screen and (max-width: 767px) {
        padding: 70px 0;
      }
      .content {
        h1 {
          margin: 0 auto;
          text-align: center;
          font-size: 65px;
          max-width: 767px;
          color: $textColor;
          margin-bottom: 20px;
          @media screen and (max-width: 991px) {
            font-size: 50px;
          }
          @media screen and (max-width: 575px) {
            font-size: 35px;
          }
          @media screen and (max-width: 450px) {
            font-size: 30px;
          }
          > span {
            color: $mainColor;
          }
        }

        p {
          text-align: center;
          font-size: 22px;

          &.approvTxt {
            color: $mainColor;
            margin-top: 50px;

            @media screen and (max-width: 480px) {
              margin-top: 20px;
            }
          }
        }
      }

      .leftImg {
        position: absolute;
        left: 0;
        width: 21%;
        bottom: -10px;
        @media screen and (max-width: 767px) {
          width: 30%;
        }
        @media screen and (max-width: 480px) {
          width: 40%;
        }
      }

      .rightImg {
        position: absolute;
        right: 0;
        width: 21%;
        bottom: -10px;
        @media screen and (max-width: 767px) {
          width: 30%;
        }
        @media screen and (max-width: 480px) {
          width: 40%;
        }
      }
    }

    .feature_main {
      margin-top: 100px;
      @media screen and (max-width: 991px) {
        margin-top: 70px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 50px;
      }

      .support_sec {
        display: flex;
        justify-content: center;
        column-gap: 25px;
        row-gap: 20px;

        @media screen and (max-width: 767px) {
          flex-wrap: wrap;
        }

        .box {
          border-radius: 20px;
          overflow: hidden;
          padding: 25px;
          box-sizing: border-box;
          flex: 1;
          transition: 0.4s;
          border: 2px solid transparent;

          &:first-child {
            background-color: rgb(255, 254, 231);
          }
          &:nth-child(2) {
            background-color: rgb(251, 241, 255);
          }
          &:last-child {
            background-color: rgb(238, 251, 250);
          }

          &:hover {
            background-color: #fff;
            border: 2px solid $mainColor;
          }

          @media screen and (max-width: 767px) {
            &:last-child {
              width: 100%;
              flex: unset;
            }
          }
          @media screen and (max-width: 475px) {
            width: 100%;
            flex: unset;
          }

          > h2 {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 15px;
            > span {
              color: $mainColor;
            }

            @media screen and (max-width: 991px) {
              font-size: 22px;
            }
          }
          > p {
            font-size: 18px;
            color: #00000080;
            margin-bottom: 15px;
            @media screen and (max-width: 991px) {
              font-size: 16px;
            }
          }
          > img {
            width: 50%;
            display: block;
            margin: 15px 0 0 auto;
            animation: jumpFour 4s infinite linear;
          }
        }
      }
    }

    /* our_history section css start */
    .our_history {
      margin-top: 100px;
      @media screen and (max-width: 991px) {
        margin-top: 70px;
      }
      @media screen and (max-width: 767px) {
        margin-top: 50px;
      }

      .main_heading {
        display: flex;
        h2 {
          font-size: 65px;
          width: 100%;

          @media (max-width: 991px) {
            font-size: 45px;
          }
          @media (max-width: 767px) {
            font-size: 37px;
          }
          @media (max-width: 500px) {
            font-size: 20px;
          }

          > span {
            color: $mainColor;
          }
        }
        img {
          margin: 25px 0;
          overflow: hidden;
          @media (max-width: 500px) {
            margin: 10px 0;
            width: 100px;
          }
        }
      }

      .history {
        > h2 {
          font-size: 25px;
          margin: 15px 0;

          @media (max-width: 500px) {
            font-size: 20px;
          }
        }
        > p {
          font-size: 18px;
        }
      }
    }
    /* our_history section css end */
  }
}
