@import "./common.scss";

.dashboardLayout_main {
  height: 100vh;
  background: #42275a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #734b6d,
    #42275a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #734b6d,
    #42275a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  overflow: hidden;

  > .childrenMain {
    height: calc(100vh - 163px);
    overflow-y: auto;
    margin-top: 80px;

    @media screen and (max-width: 767px) {
      height: calc(100vh - 156px);
      margin-top: 72px;
    }
  }
}
