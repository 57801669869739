@import "./common";

.page_not_found {
  height: 100vh;
  width: 100%;
  // background-image: url("../images/contact_bg.png");
  background-color: $textColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 15px;
  box-sizing: border-box;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    > div.not_found_content {
      width: 500px;
      @media screen and (max-width: 610px) {
        width: unset;
      }

      h1 {
        text-align: center;
        color: #fff;
        font-size: 150px;
        // font-family: $casanova;
        margin-bottom: 25px;
        @media screen and (max-width: 610px) {
          font-size: 100px;
        }
        @media screen and (max-width: 430px) {
          font-size: 50px;
        }
      }

      >title{
        display: block;
      }

      p.subheading {
        text-align: center;
        color: #fff;
        font-size: 20px !important;
        // font-family: $monserrat;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-weight: 700;
      }

      > p {
        font-weight: lighter;
        color: #fff;
        text-align: center;
        font-weight: 18px;
        margin-bottom: 25px;
      }

      > a {
        padding: 13px 25px;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 8px;
        text-transform: capitalize;
        display: block;
        width: fit-content;
        margin: 0 auto;
        transition: 0.4s;
        display: flex;
        align-items: center;

        > svg {
          margin-left: 5px;
        }

        &:hover {
          background-color: #f2f2f2;
          color: $textColor;
        }
      }
    }
  }
}
