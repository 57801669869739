@import "./common.scss";

.table-responsive {
  overflow-x: auto;
  width: 100%;

  .customTable {
    border-collapse: collapse;
    white-space: nowrap;
    border: 1px solid #ffffff3a;
    width: 100%;

    th,
    td {
      border: 1px solid #ffffff3a;
      color: #f2f2f2;
      padding: 5px;
    }

    th {
      background-color: $textColor;
      font-weight: 500;
    }
  }
}
