@import "./common.scss";

.reset_password_main {
  background: #42275a;
  background: linear-gradient(to right, #734b6d, #42275a);
  height: 100vh;
  padding: 0 15px;

  > .card_reset_pass {
    position: fixed;
    width: calc(100% - 30px);
    background: darkturquoise;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    max-width: 500px;

    .input_text {
      color: #f2f2f2;
    }
    .input_text::placeholder {
      color: #000000af; /* Replace with your desired color */
      opacity: 1; /* Adjust if you want the color to be fully opaque */
    }
    .changePassBtn {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      box-shadow: inset 0px 1px 10px 1px #000;
      display: block;
      margin: 0 auto;
      color: #42275a;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 1px 10px 1px #000;
      }
    }

    .label_container {
      display: flex;
      align-items: center;
      column-gap: 20px;
      color: #fff;
      font-weight: 500;
      margin-bottom: 20px;

      > label {
        display: flex;
        column-gap: 5px;
        cursor: pointer;
      }
    }
  }
}
