@import "./common.scss";

.myAccount_main {
  .userContent {
    margin-top: 40px;
    margin-bottom: 40px;

    .profilePic {
      display: flex;
      column-gap: 15px;
      @media screen and (max-width: 460px) {
        flex-direction: column;
      }

      > .imgSec {
        @media screen and (max-width: 460px) {
          display: flex;
          justify-content: space-between;
        }
        > img {
          width: 150px;
          height: 150px;
          background-color: $textColor;
          border-radius: 20px;
          object-fit: contain;
          //   @media screen and (max-width: 460px) {
          //     margin-right: auto;
          //     margin-left: auto;
          //     display: block;
          //   }
        }
        > .editBtn {
          > button {
            width: 100%;
            border-radius: 20px;
            padding: 10px 30px;
            border: none;
            margin-top: 15px;
            color: $textColor;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            > svg {
              margin-right: 5px;
              display: flex;
              font-size: 20px;
            }
            &:hover{
              background-color: $textColor;
              color: #fff;
            }
          }
        }
      }

      > .impData {
        > .fullName {
          font-size: 40px;
          color: #f2f2f2;
          text-transform: uppercase;
          font-weight: 500;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          column-gap: 10px;

          @media screen and (max-width: 767px) {
            font-size: 30px;
          }
         
          @media screen and (max-width: 520px) {
            font-size: 25px;
          }

          @media screen and (max-width: 460px) {
            text-align: center;
            font-size: 20px;
            margin-top: 20px;
          }
        }

        .uniqueID,
        .emailID {
          // background-color: #f2f2f2;
          padding: 5px 15px;
          border-radius: 20px;
          margin-bottom: 10px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          background: lightslategray;
          color: #fff;
          box-shadow: inset 0 0 10px 0px #000;
          > svg {
            cursor: pointer;
            &:hover {
              color: $mainColor;
            }
          }
        }
      }
    }

    .userData {
      margin-top: 40px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 20px;

      > div {
        flex: 1 1 49%; /* Flex-grow, flex-shrink, flex-basis */
        box-sizing: border-box; /* Ensures padding and border are included in the width */
        @media screen and (max-width: 1199px) {
          flex: 1 1 48%; /* Flex-grow, flex-shrink, flex-basis */
        }
      }
      input {
        background-color: #f2f2f2;
        color: $textColor;
        font-size: 18px;

        @media screen and (max-width: 530px) {
          font-size: unset;
        }
      }
    }
    button {
      display: block;
      margin: 0 auto;
      padding: 10px 30px;
      font-size: 18px;
      font-weight: 500;
      border-radius: 20px;
      border: none;
      cursor: pointer;
      color: $textColor;
    }
  }
}
