@import "./common.scss";

.header_main {
  padding: 10px 0;
  box-shadow: 0 0 5px 1px #00000045;

  .header_content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo img {
      width: 100px;
    }

    .links {
      > a {
        font-size: 18px;
        margin-right: 20px;
        color: #000;

        &:hover {
          color: $textColor;
        }

        &:last-child {
          margin-right: 0;
        }

        &.userBtn {
          background: $linearBg;
          color: #fff;
          padding: 5px 10px;
          transition: 0.4s;

          &:hover {
            background: $linearHoverBg;
          }
        }
      }
    }
  }
}
