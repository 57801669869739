@import "./common.scss";

.dashboard_main {
  .dashTopData {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-top: 40px;
    flex-wrap: wrap;
    row-gap: 20px;
    > div.dataCard {
      flex: 1;
      border: 1px solid transparent;
      transition: 0.4s;
      cursor: pointer;
      @media screen and (max-width: 991px) {
        &:last-child {
          width: 100%;
          flex: unset;
        }
      }
      @media screen and (max-width: 767px) {
        flex: 1;
        min-width: 224px;
        &:last-child {
          width: unset;
          flex: 1;
        }
      }
      @media screen and (max-width: 500px) {
        min-width: 150px;

        .mainData {
          font-size: 20px;
        }
        .title {
          font-size: 20px;
        }
        .desc {
          font-size: 10px;
        }
        .button {
          font-size: 14px;
        }
      }
      @media screen and (max-width: 370px) {
        .title {
          font-size: 16px;
        }
      }
      @media screen and (max-width: 345px) {
        min-width: 135px;
      }
      @media screen and (max-width: 319px) {
        min-width: unset;
      }
      &:hover {
        border: 1px solid $mainColor;
        background: #f2f2f2 !important;
      }
    }
  }

  .chartContent {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    column-gap: 20px;
    row-gap: 20px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    > div {
      flex: 1;
      padding: 16px;
      border-radius: 20px;
      max-height: 350px;
      &:last-child {
        overflow-y: auto;
      }

      > .cardHeading {
        color: #fff;
        font-size: 18px;
        margin-bottom: 10px;
        width: fit-content;
        background-color: $textColor;
        padding: 8px 20px;
        border-radius: 50px;
        font-weight: 500;
        box-sizing: border-box;
      }

      &.chart {
        canvas {
          width: fit-content !important;
          height: 280px !important;
          display: block;
          margin: auto;

          @media screen and (max-width: 375px) {
            height: -webkit-fill-available !important;
            width: -webkit-fill-available !important;
          }
        }

        .policyData {
          display: flex;
          align-items: center;
          column-gap: 10px;
          font-size: 10px;
          flex-wrap: wrap;
          margin-top: 10px;

          > .nameColor {
            display: flex;
            align-items: center;
            column-gap: 5px;
            span {
              &:first-child {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: block;
              }
            }
          }
        }
      }

      &.impMsg {
        > ul {
          margin-left: 16px;
          // max-height: 500px;
          // overflow-y: auto;
          > li {
            margin-bottom: 3px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
