@import "./common.scss";

.helpMain {
  .helpContent {
    .top_section {
      display: flex;
      // justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        row-gap: 20px;
      }
      > div {
        text-align: center;
        flex: 1;
        padding: 15px 10px;
        box-sizing: border-box;
        transition: 0.4s;
        cursor: pointer;
        border: 1px solid transparent;

        &:first-child {
          background-color: rgb(255, 254, 231);
        }
        &:nth-child(2) {
          background-color: rgb(251, 241, 255);
        }
        &:last-child {
          background-color: rgb(238, 251, 250);
        }

        &:hover {
          border: 1px solid $mainColor;
          background-color: #f2f2f2;
        }

        a {
          color: $mainColor;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }

        @media screen and (max-width: 767px) {
          border-radius: 20px;
          overflow: hidden;
        }

        > .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          padding: 10px;
          border: 2px solid $mainColor;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
          @media screen and (max-width: 767px) {
            margin-bottom: 8px;
          }
          svg {
            font-size: 25px;
            color: black;
          }
        }

        > h3 {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 10px;
          @media screen and (max-width: 767px) {
            margin-bottom: 8px;
          }
        }
        > p {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 10px;
          @media screen and (max-width: 767px) {
            margin-bottom: 8px;
          }
        }
      }
    }

    .faqSection {
      margin-top: 40px;

      > .secTitle {
        color: #f2f2f2;
      }

      > .faqDropdown {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        > .dropdown-item {
          background-color: #fff;
          border-radius: 15px;
          overflow: hidden;

          > .dropHeading {
            font-size: 18px;
            padding: 10px;
            transition: 0.4s;
            font-weight: 500;
            color: $mainColor;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            &.active {
              background-color: $mainColor;
              color: #f2f2f2;

              > .icon {
                transform: rotate(180deg);
              }
            }

            > .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              height: 20px;
              width: 20px;
              z-index: 1;
              transition: 0.4s;
              margin-left: 7px;
            }
          }

          > .dropData {
            height: 0;
            overflow: hidden;
            transition: 0.4s;

            &.showData {
              height: auto;
              padding: 0 10px 10px;
            }
          }
        }
      }
    }
  }
}
