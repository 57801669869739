@import "./common.scss";

.dashboardBottomBar_main {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 20px 20px 0 0;
  border-top: 5px solid lightgray;
  box-sizing: border-box;
  overflow: hidden;
  background: #f2f2f2;

  > .wrapper {
    > .mainLinks {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      > a,
      > div.action {
        padding: 15px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: $textColor;
        transition: 0.4s;
        cursor: pointer;

        @media screen and (max-width: 450px) {
          padding: 15px 10px;
        }

        @media screen and (max-width: 380px) {
          font-size: 11px;
        }

        > svg {
          font-size: 25px;
        }

        &:hover {
          color: $mainColor;
        }

        &.active {
          color: $mainColor;
        }
      }
    }
  }
}

.dashboardBottomBar_moreLinks {
  // transform: translate(0);
  // height: 0;
  // transition: 0.4s;
  position: absolute;
  bottom: 65px;
  right: 0;
  background: #f2f2f2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-sizing: border-box;
  box-shadow: 0px -5px 6px 0px #00000029;
  overflow: hidden;
  z-index: 1;

  > a,
  > div {
    display: block;
    padding: 15px 55px;
    font-size: 18px;
    color: $mainColor;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }

  &.fade-in {
    animation: fadeIn 0.5s forwards;
  }

  &.fade-out {
    animation: fadeOut 0.5s forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
