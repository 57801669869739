@import "./common.scss";

.agentPoliciesMain {
  .label_container {
    display: flex;
    align-items: center;
    column-gap: 1px;
    margin-bottom: 15px;

    > label {
      font-weight: 500;
      background-color: #fff;
      color: $mainColor;
      padding: 10px;
      font-size: 15px;
      cursor: pointer;

      &:first-child {
        border-radius: 20px 0 0 20px;
      }
      &:last-child {
        border-radius: 0 20px 20px 0;
      }

      @media screen and (max-width: 332px) {
        font-size: 14px;
      }

      > input {
        margin-right: 5px;
      }
    }
  }

  .datePickerLabel {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #f2f2f2;
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }

  .react-datepicker__input-container {
    > input {
      display: block;
      width: 100%;
      height: 50px;
      background: linear-gradient(
        115.85deg,
        rgba(255, 255, 255, 0.4) -185.19%,
        rgba(255, 255, 255, 0) 129.07%
      );
      backdrop-filter: blur(21px);
      border-radius: 4px;
      border: none;
      outline: none;
      box-sizing: border-box;
      padding: 10px;
      color: #a9b5bb;
      font-size: 14px;
      margin-bottom: 25px;

      @media screen and (max-width: 575px) {
        height: 40px;
        font-size: 13px;
        margin-bottom: 20px;
      }

      ::placeholder {
        color: #f2f2f2 !important; /* Change this to your desired color */
        opacity: 0.5 !important; /* Adjust opacity if needed */
      }
    }
  }
}
